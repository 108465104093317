<template>
  <div class="Youshi">
    <div class="banner">

    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3>Our <span>Advantages</span></h3>
        </div>
        <p>When applying for a university to study abroad, the most important thing is to choose the right University and major. You need to compare different choices in order to make the best choice and find a school worth 4 years or more to complete your studies. One advantage of services such as "ZHUXUE" is to directly help you connect with universities and projects, communicate and understand directly with teachers in the International Department of the University, and obtain the most cutting-edge and trusted consulting services.</p>
        <p>ZHUXUE cooperates with more than 400 universities and colleges in China, and the number continues to grow. You can contact directly with colleges and universities admissions through our platform. Please tell us your application needs and you will find out how convenient and fast the application could be.</p>
        <p>Thanks to our cooperative universities and colleges, we have more than 300 high-tech talent training projects for you to choose: China hydrogen energy heavy truck, The Research on Biopharmaceutical Innovation, UHV DC, core electronic devices, etc. High tech projects take you to understand China, learn knowledge and create unlimited possibilities for your future. </p>
        <p>In order to facilitate the application for scholarships, ZHUXUE has cooperated with hundreds of universities. All these universities put their scholarship projects on the ZHUXUE platform, from which you can choose and apply; You can also apply after consulting with our university consultants.</p>
        <p>ZHUXUE can help you choose universities in more than 70 cities in China. From Xinjiang to Shanghai, from Heilongjiang to Hainan, you can always find your destination to study in China.</p>
        <p>Applying for a Chinese University is a serious job. We recommend you favorite university and provide you with other services, such as HSK examinations guidance, visa processing of studying in China, insurance processing, bank card processing, air ticket reservation, hotel reservation, pick-up service, internship projects, etc. You need a reliable service platform to help you out, so why not choose ZHUXUE. We are always here to help, and we helped many foreign students in China and won the trust of many users.</p>
        <div class="tit">
          Accuracy
        </div>
        <p>For an integrated service platform, providing accurate information service is the primary role. Whether it is the information docking of a university or the submission of scholarship applications, ZHUXUE team always tries to keep improving. With the continuous efforts of the our team, more and more colleges and universities join us to serve together. For the scholarship program, you can see the clear scholarship amount and application process. In order to make our information more timely and effective, our team keeps close communication with colleges and universities and updates new college projects every month. This is why you can find first-hand information on ZHUXUE.</p>
        <div class="tit">
          Free connection to China University Admission Service
        </div>
        <p>Provide free advice to international students applying to study in China. Free application support services will maximize your chances of being admitted to Chinese university courses. This service is free to all international students.</p>
        <p>Our close relationship with Chinese universities enables your ZX-CSCSE application to be Preferentially reviewed, and receiving the admission notice faster than that applied on your own. This service means that 98% of ZHUXUE's applicants have received the admission notice from the preferred university, while only 55% apply independently.</p>
        <p>We have sent tens of thousands of students coming to Chinese universities. You may be the next one. What does it include?</p>
        <p> <i class="el-icon-circle-check"></i> Through telephone or Wechat, you can consult the consultants in Beijing for 40 minutes free to learn about China’s universities.</p>
        <p> <i class="el-icon-circle-check"></i> List of recommended Universities</p>
        <p> <i class="el-icon-circle-check"></i> We will provide you with and review your application form</p>
        <p> <i class="el-icon-circle-check"></i> Review your personal statement</p>
        <p> <i class="el-icon-circle-check"></i> Suggestions on what documents Chinese universities need</p>
        <p> <i class="el-icon-circle-check"></i> Recommendations on available Scholarships</p>
        <p> <i class="el-icon-circle-check"></i> Suggestions on deciding which proposal to accept</p>
        <p> <i class="el-icon-circle-check"></i> Support the whole application process until registration</p>
        <p>If you want ZHUXUE to provide all the above services and ensure your admission to a favorite Chinese University, please learn more about advanced services.</p>
        <div class="tit">
          service
        </div>
        <el-row :gutter="24">
          <el-col :span="8">
            <div class="lis">
              <div class="num">1</div>
              <div class="text">
                Free consultation in cooperative universities
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">2</div>
              <div class="text">
                Charge for application consulting services
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">3</div>
              <div class="text">
                On the same day service
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">4</div>
              <div class="text">
                The school is recommended
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">5</div>
              <div class="text">
                MBBS apply for consulting services
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">6</div>
              <div class="text">
                PHD application consulting services
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">7</div>
              <div class="text">
                Visa services
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">8</div>
              <div class="text">
                Service in China
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="num">9</div>
              <div class="text">
                Chinese Customs clearance course service
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="tit">
          Reliability
        </div>
        <p>ZHUXUE can not be simply be defined as an agent. Cooperation with colleges and universities is very important. Whenever a university or institution wants to serve students with us, we will conduct sufficient investigation to understand the advantages and characteristics of the project, so as to ensure that our students are free from unnecessary trouble. Our partners include Tsinghua University, Zhejiang University, Shanghai Jiaotong University, Wuhan University and other top universities.</p>
        <div class="tit">
          Recognition
        </div>
        <p>Most Chinese universities, official institutions and international organizations are qualified partners of ZHUXUE.</p>
        <el-row :gutter="24">
          <el-col :span="4"
                  v-for="(item,index) in hezuoLogo"
                  :key="index">
            <div class="lis">
              <div class="imgbox">
                <img :src="item.img"
                     alt="">
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="tit">
          Most popular
        </div>
        <p>ZHUXUE is most trusted by international students and the most trusted platform for most students to apply for Chinese universities. Every year, more than 10000 international students from 200 countries consult, and more than 4000 students submit admission applications. We are the choice of most people in this industry.</p>
        <p>ZHUXUE is the most reliable university application platform in China.</p>
        <p><b>Join us!</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hezuoLogo: [
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
        {
          img: require('@/assets/hezuologo.png'),
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .container > p {
    font-size: 14px;
    text-indent: 2em;
    line-height: 2;
    margin-bottom: 10px;
    i {
      color: #0c3879;
    }
  }
  .tit {
    border-left: 8px solid #0c3879;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 1;
  }
  .lis {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .num {
      display: flex;
      width: 40px;
      height: 40px;
      background-color: #0c3879;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
    }
    .text {
      width: calc(100% - 40px);
      padding-left: 10px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dedede;
      border-left: none;
      font-size: 14px;
    }
  }
}
</style>